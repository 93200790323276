import * as BoxIcons from "react-icons/bi";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as TbIcons from "react-icons/tb";
import * as RiIcons from "react-icons/ri";
import {SessionManagement} from "../library/SessionManagement";

const whatsappSetStatus = SessionManagement.getIsWhatsappSet();
console.log("Whatsapp set ==>>>" + whatsappSetStatus);

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiLayout className="icon" />,
  },
  // {
  //   title: "Account",
  //   path: "/account",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <BoxIcons.BiMoney className="icon" />,
  // },
  // whatsappSetStatus === "Active" ?
  // {
  //   title: "Whatsapp",
  //   path: "/whatsapp",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
  //   iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
  //   icon: <BsIcons.BsWhatsapp className="icon" />,
  //   submenu: [
  //     {
  //       title: "Messages",
  //       path: "/whatsapp/messages",
  //     },
  //     {
  //       title: "Templates",
  //       path: "/whatsapp/templates",
  //     },
  //     {
  //       title: "Settings",
  //       path: "/whatsapp/settings",
  //     },
  //   ],
  // } :
  // {
  //   title: "Whatsapp",
  //   path: "/whatsapp/messages",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <BsIcons.BsWhatsapp className="icon" />,
  // },
  {
    title: "Shipments",
    path: "/shipments",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
    iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
    icon: <BoxIcons.BiPackage className="icon" />,
    submenu: [
      {
        title: "Create Shipment",
        path: "/create-shipment",
      },
      {
        title: "All Shipments",
        path: "/shipments/all-shipments",
      },
      {
        title: "Pending Approval",
        path: "/shipments/pending-approval",
      },
      {
        title: "Pending Pickup",
        path: "/shipments/pending-pickup",
      },
      {
        title: "Picked Up",
        path: "/shipments/picked-up",
      },
      {
        title: "Pending Delivery",
        path: "/shipments/pending-delivery",
      },
      {
        title: "Delivered",
        path: "/shipments/delivered",
      },
      {
        title: "Rejected",
        path: "/shipments/rejected",
      },
      {
        title: "Cancelled",
        path: "/shipments/cancelled",
      },
      {
        title: "Batches",
        path: "/shipments/batches",
      },
    ],
  },
  // {
  //   title: "Pending Approvals",
  //   path: "/pending-kyc",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
  //   iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
  //   icon: <BoxIcons.BiUserCheck className="icon" />,
  //   submenu: [
  //     {
  //       title: "KYC",
  //       path: "/pending-kyc",
  //     },
  //   ]
  // },
  // {
  //   title: "Transactions",
  //   path: "/transactions",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <BoxIcons.BiCoinStack className="icon" />,
  // },
  {
    title: "Customers",
    path: "/customers",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiUser className="icon" />,
  },
  // {
  //   title: "Customers Wallet",
  //   path: "/customers-wallet",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <BsIcons.BsWallet2 className="icon" />,
  // },
  // {
  //   title: "Admins",
  //   path: "/admins",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <BoxIcons.BiUserCircle className="icon" />,
  // },
  // {
  //   title: "Marketers",
  //   path: "/marketers",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <BoxIcons.BiUserCircle className="icon" />,
  // },
  // {
  //   title: "Stations & Hubs",
  //   path: "/stations",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <HiIcons.HiOutlineOfficeBuilding className="icon" />,
  //   submenu: [
  //     {
  //       title: "Create Station",
  //       path: "/stations/create",
  //     },
  //     {
  //       title: "All Stations",
  //       path: "/stations",
  //     }
  //   ],
  // },
  // {
  //   title: "Partners",
  //   path: "/partners",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <RiIcons.RiUserStarLine className="icon" />,
  // },
  // {
  //   title: "Discounts & Promos",
  //   path: "/discounts-promos",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <AiIcons.AiOutlineTag className="icon" />,
  // },
  {
    title: "Transporters",
    path: "/transporters",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiRun className="icon" />,
  },
  // {
  //   title: "Reports",
  //   path: "/reports",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <TbIcons.TbBrandGoogleAnalytics className="icon" />,
  // },
  {
    title: "Settings",
    path: "/settings",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiCog className="icon" />,
  },
];

import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useNavigate, useParams} from "react-router-dom";
import ShipmentTimeline from "./Widgets/ShipmentTimeline";
import { useEffect, useState } from "react";
import ShipmentInformation from "./Widgets/ShipmentInformation";
import {getShipmentDetailsURL} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {ErrorNotification, SuccessNotification} from "../components/Notifications";
import ShipmentPath from "./Widgets/ShipmentPath";
import DHLShipmentContainer from "./Widgets/DHLShipmentContainer";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CancelShipmentDialog from "../modals/CancelShipmentDialog";
import ReRouteShipmentDialog from "../modals/ReRouteShipmentDialog";
import moment from "moment";
import ApproveTerminalShipmentDialog from "../modals/ApproveTerminalShipmentDialog";
import * as React from "react";
import styled from "styled-components";
import {SessionManagement} from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";


const LoadingContainer = styled.div`
  height: 155px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SingleShipment = () => {
  const [showApproveShipmentDialog, setShowApproveShipmentDialog] = useState(false);

  const [isRetrievingDetails, setIsRetrievingDetails] = useState(true);

  const params = useParams();
  const navigator = useNavigate();
  const shipmentID = params["shipmentID"];

  const [isShowRouteShipmentDialogVisible, setShowRouteShipmentDialogVisibility] = useState(false);
  const [isShowReRouteShipmentDialogVisible, setShowReRouteShipmentDialogVisibility] = useState(false);
  const [isShowCancelShipmentDialogVisible, setShowCancelShipmentDialogVisibility] = useState(false);

  const [shipmentTime, setShipmentTime] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [senderID, setSenderID] = useState("");
  const [senderType, setSenderType] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [statusClass, setStatusClass] = useState("");
  const [description, setDescription] = useState("");
  const [shipmentNumber, setShipmentNumber] = useState("");
  const [shipmentType, setShipmentType] = useState("");
  const [dropoffCoordinates, setDropoffCoordinates] = useState("");
  const [pickupCoordinates, setPickupCoordinates] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [weight, setWeight] = useState("");
  const [riderName, setRiderName] = useState("");
  const [itemValue, setItemValue] = useState("");
  const [itemValueCurrency, setItemValueCurrency] = useState("");

  const [dhlProcessed, setDHLProcessed] = useState("");
  const [processor, setProcessor] = useState("");
  const [waybillURL, setWaybillURL] = useState("");
  const [invoiceURL, setInvoiceURL] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");

  const toggleRouteShipmentDialog = (type) => {
    type === "open" ? setShowRouteShipmentDialogVisibility(true) : setShowRouteShipmentDialogVisibility(false);
  }

  const toggleReRouteShipmentDialog = (type) => {
    type === "open" ? setShowReRouteShipmentDialogVisibility(true) : setShowReRouteShipmentDialogVisibility(false);
  }

  const toggleCancelShipmentDialog = (type) => {
    type === "open" ? setShowCancelShipmentDialogVisibility(true) : setShowCancelShipmentDialogVisibility(false);
  }

  const data = {
    shipment_id: shipmentID,
  };

  const newData = { ...data, ...UserProfileData() };

  const toggleApproveTerminalShipment = (type) => {
    type === "open" ? setShowApproveShipmentDialog(true) : setShowApproveShipmentDialog(false);
  };

  const goToSender = () => {
    if (senderType === "Customer"){
      navigator("/customer/" + senderID);
    } else if (senderType === "Admin"){
      navigator("/admin/" + senderID);
    }
  }

  const reCreateShipment = () => {
    navigator("/duplicate-shipment/" + shipmentID);
  };

  const fetchShipmentInfo = () => {
    axios
      .post(getShipmentDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsRetrievingDetails(false);
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        const sender = shipmentDetails["data"]["user_id"];
        const senderType = sender.toString().split("-")[0];
        const senderID = sender.toString().split("-")[1];

        const senderName = shipmentDetails["data"]["sender_name"];
        const senderPhoneNumber = shipmentDetails["data"]["sender_phone_no"];
        const receiverName = shipmentDetails["data"]["recipient_name"];
        const receiverPhoneNumber = shipmentDetails["data"]["recipient_phone_no"];
        const amount = shipmentDetails["data"]["total_amount"];
        const paymentMethod = shipmentDetails["data"]["payment_method"];
        const pickupLocation = shipmentDetails["data"]["pick_up_location"];
        const dropoffLocation = shipmentDetails["data"]["drop_off_location"];
        const status = shipmentDetails["data"]["status"];
        const paymentStatus = shipmentDetails["data"]["paid_status"];
        const description = shipmentDetails["data"]["item_name"];
        const riderName = shipmentDetails["data"]["rider_name"];
        const itemValue = shipmentDetails["data"]["item_value"];
        const itemValueCurrency = shipmentDetails["data"]["item_value_currency"];
        const weight = shipmentDetails["data"]["weight"];
        const shipmentType = shipmentDetails["data"]["shipment_type"];
        const shipmentNumber = shipmentDetails["data"]["shipment_no"];
        const dropoffCoordinates =
            shipmentDetails["data"]["drop_off_latitude"] +
            "," +
            shipmentDetails["data"]["drop_off_longitude"];
        const pickupCoordinates =
            shipmentDetails["data"]["pick_up_latitude"] +
            "," +
            shipmentDetails["data"]["pick_up_longitude"];

        const approvedBy = shipmentDetails["data"]["approved_by"];

        const dhlProcessed = shipmentDetails["data"]["dhl_processed"];
        const processor = shipmentDetails["data"]["processor"];
        const waybillURL = shipmentDetails["data"]["pdf_document"];
        const invoiceURL = shipmentDetails["data"]["invoice_url"];
        const trackingNumber =
            shipmentDetails["data"]["shipment_tracking_number"];

        const timestamp = shipmentDetails["data"]["date_time"];
        const shipmentTime = moment(timestamp).format('ddd MMM Do, h:mm A');

        setShipmentTime(shipmentTime);

        const statusClass = status.toString().replaceAll(" ", "");
        setStatusClass(statusClass);

        if (processor === "TerminalAfrica"){
          setPickupCoordinates("6.596413,3.353785");
          setDropoffCoordinates("6.614775,3.356861");
        } else {
          setPickupCoordinates(pickupLocation);
          setDropoffCoordinates(dropoffLocation);
        }

        setSenderID(senderID);
        setSenderType(senderType);
        setSenderName(senderName);
        setSenderPhoneNumber(senderPhoneNumber);
        setReceiverName(receiverName);
        setAmount(amount);
        setReceiverPhoneNumber(receiverPhoneNumber);
        setPaymentMethod(paymentMethod);

        console.log("Payment status is " + paymentStatus);
        setStatus(status);
        setPaymentStatus(paymentStatus);
        setDescription(description);
        setShipmentType(shipmentType);
        setApprovedBy(approvedBy);
        setPickupLocation(pickupLocation);
        setDropoffLocation(dropoffLocation);
        setShipmentNumber(shipmentNumber);
        setRiderName(riderName);
        setItemValue(itemValue === undefined ? "" : itemValue);
        setItemValueCurrency(itemValueCurrency === undefined ? "" : itemValueCurrency);
        setWeight(weight === undefined ? "" : weight);
        setDHLProcessed(dhlProcessed);
        setProcessor(processor);
        setWaybillURL(waybillURL);
        setInvoiceURL(invoiceURL);
        setTrackingNumber(trackingNumber);
      });
  };

  useEffect(() => {
    fetchShipmentInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Shipment Detail - {shipmentNumber}</h5>
            <span>{shipmentTime}</span>
          </div>




          <div className="d-flex flex-row mx-3 justify-content-between">
            {
              status === "Pending Approval" ? 
              // processor === "TerminalAfrica" || processor === "DHL" ?
                  <div>
                    <button style={{marginRight:15}} className="confirm-button" onClick={(e) => toggleApproveTerminalShipment("open")}>APPROVE SHIPMENT</button>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-red-button">CANCEL SHIPMENT</button>
                  </div> 
                  // :
                  // <div>
                  //   <button style={{marginRight:15}} className="confirm-button" onClick={(e) => toggleRouteShipmentDialog("open")}>ROUTE SHIPMENT</button>
                  //   <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-red-button">CANCEL SHIPMENT</button>
                  // </div> 
                  :
              processor === "Self" && (status === "Pending Pickup" || status === "Going To Pickup" || status === "Picked Up" || status === "Going To Deliver") ?
                  <div>
                    <button style={{marginRight:15}} className="confirm-button" onClick={(e) => toggleReRouteShipmentDialog("open")}>RE-ROUTE SHIPMENT</button>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")}  className="cancel-red-button">CANCEL SHIPMENT</button>
                  </div> :
                  status !== "Cancelled" ? <button onClick={(e) => toggleCancelShipmentDialog("open")}  className="cancel-red-button">CANCEL SHIPMENT</button> : <div></div>
            }


            <div className="d-flex flex-row">
              <div className={`status ${statusClass}`}>
                {status}
              </div>
              {
                shipmentType === "International" || shipmentType === "Interstate" ?
                    <button style={{marginLeft:15}} onClick={(e) => reCreateShipment()}  className="confirm-button">RE-CREATE SHIPMENT</button>
                    : null
              }
            </div>

          </div>

          <ApproveTerminalShipmentDialog
              shipmentID={shipmentID}
              isModalVisible={showApproveShipmentDialog}
              handleCloseDialog={(e) => toggleApproveTerminalShipment("close")}
              fetchShipmentInfo={fetchShipmentInfo}
          />

          <RouteShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={isShowRouteShipmentDialogVisible}
            handleCloseDialog={(e) => toggleRouteShipmentDialog("close")}
          />

          <ReRouteShipmentDialog
              shipmentID={shipmentID}
              isModalVisible={isShowReRouteShipmentDialogVisible}
              handleCloseDialog={(e) => toggleReRouteShipmentDialog("close")}
          />

          <CancelShipmentDialog
              shipmentID={shipmentID}
              isModalVisible={isShowCancelShipmentDialogVisible}
              handleCloseDialog={(e) => toggleCancelShipmentDialog("close")}
              fetchShipmentInfo={fetchShipmentInfo}
          />

          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-xl-6 col-md-12">
                <span className="header">Sender Details</span>
                {
                  isRetrievingDetails === true ?
                      <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                      </LoadingContainer> :
                      <div className="card bg-light border-0 shadow">
                        <div className="card-body shipper-container">
                          <div className="d-flex justify-content-between">
                            <div>
                              <span className="title">Name</span>
                              <p onClick={(e) => goToSender()} className="sender-name">{senderName}</p>
                            </div>
                            <div>
                              <span className="title">Phone Number</span>
                              <p>{senderPhoneNumber}</p>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="title">Pickup Location</span>
                            <p>{pickupLocation}</p>
                          </div>
                        </div>
                      </div>
                }
              </div>

              <div className="col-xl-6 col-md-12">
                <span className="header">Receiver Details</span>
                {
                  isRetrievingDetails === true ?
                  <LoadingContainer>
                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                  </LoadingContainer> :
                  <div className="card bg-light border-0 shadow">
                    <div className="card-body shipper-container">
                      <div className="d-flex justify-content-between">
                        <div>
                          <span className="title">Name</span>
                          <p>{receiverName}</p>
                        </div>
                        <div>
                          <span className="title">Phone Number</span>
                          <p>{receiverPhoneNumber}</p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <span className="title">Dropoff Location</span>
                        <p>{dropoffLocation}</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="row mt-4">
              <ShipmentInformation
                isRetrieving={isRetrievingDetails}
                amount={amount}
                paymentStatus={paymentStatus}
                description={description}
                approvedBy={approvedBy}
                weight={weight}
                processor={
                  processor === "Self" ? riderName : processor
                }
                itemValue={itemValue}
                itemValueCurrency={itemValueCurrency}
                paymentMethod={paymentMethod}
                shipmentID={shipmentID}
                shipmentNumber={shipmentNumber}
              />
            </div>

            <div className="row mt-4">
              {processor === "Self" ? (null
                  // <ShipmentPath
                  //     pickupCoordinates={pickupCoordinates}
                  //     dropoffCoordinates={dropoffCoordinates}
                  // />
              ) : (
                <DHLShipmentContainer
                  processor={processor}
                  dhlProcessed={dhlProcessed}
                  waybillURL={waybillURL}
                  trackingNumber={trackingNumber}
                  invoiceURL={invoiceURL}
                  status={status}
                />
              )}

              <ShipmentTimeline shipmentID={shipmentID} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleShipment;

import "../../styles/home.scss";
import "../../styles/reports.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import { getShipmentsURL, getWalletReportUrl } from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import dataIcon from "../../images/no-data.png";
import { Dropdown } from "react-bootstrap";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";
import * as ExcelJS from "exceljs";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const WalletReports = () => {
  const navigator = useNavigate();

  const [reportType, setReportType] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [isOptionSelected, setIsOptionSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [shipments, setShipments] = useState([]);

  const data = {
    limit: 30,
    offset: currentOffset,
    report_type: reportType,
    report_time: reportTime,
    status: "All",
  };

  useEffect(() => {
    fetchWalletReports();
    // eslint-disable-next-line
  }, [])

  const fetchWalletReports = () => {
    setIsLoading(true);
    const reportData = {
      ...UserProfileData(),
      mode: "web",
    };
    if (
      (reportTime === "custom" &&
        reportStartDate !== "" &&
        reportEndDate !== "") ||
      reportTime !== "custom"
    ) {
      axios
        .post("https://admin.parcelflow.io/engine/v1/test/create-wallet-report.php", reportData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          setIsRetrievingMore(false);
          setIsLoading(false);

          if (response["data"]["success"] === false) return;

          const newShipments = response["data"]["data"];

          const index = newShipments[newShipments.length - 1].id;
          setShipments(newShipments);

          setCurrentOffset(index);

          // if (currentOffset === 0){
          //   setShipments(newShipments);
          // } else {
          //   setShipments([...shipments, ...newShipments]);
          // }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };



  const onChangeReportTime = (event) => {
    setReportTime(event.target.value);
  };

  const handleClick = (shipmentID) => {
    navigator("/shipment/" + shipmentID);
  };

  const setScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      isRetrievingMore === false
    ) {
      setIsRetrievingMore(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Report");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "CUSTOMER NAME",
        key: "customer_name",
        width: 50,
      },
      {
        header: "ACCOUNT BALANCE",
        key: "account_balance",
        width: 50,
      },
      {
        header: "CURRENT YEAR VALUE (DEBIT)",
        key: "this_year_debit_value",
        width: 50,
      },
      {
        header: "CURRENT YEAR VALUE (CREDIT)",
        key: "this_year_credit_value",
        width: 50,
      },
      {
        header: "CURRENT YEAR TRANSACTIONS",
        key: "this_year_transactions",
        width: 50,
      },
      {
        header: "CURRENT YEAR BALANCE",
        key: "last_year_balance",
        width: 50,
      },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
    WALLET REPORT
    `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    shipments?.forEach((shipment, id) => {
      sheet.addRow({
        sn: id + 1,
        customer_name: `${shipment.customer_name ?? "N/A"}`,
        account_balance: `${shipment?.account_balance ?? "N/A"}`,
        this_year_debit_value: `${shipment?.this_year_debit_value ?? "N/A"}`,
        this_year_credit_value: `${shipment?.this_year_credit_value ?? "N/A"}`,
        this_year_transactions: `${shipment?.this_year_transactions ?? "N/A"}`,
        last_year_balance: `${shipment?.last_year_balance ?? "N/A"}`
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `WALLET_REPORT.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Shipment ID,Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Weight,Shipment Tracking Number,Approved By,Payment Method, Payment Status, Shipment Status, Item Description, Item Value, Rider, Carrier" +
      "\r\n";

    shipments.forEach(function (shipment) {
      let id = shipment.id;

      let senderDetails =
        shipment.sender_name +
        "," +
        shipment.sender_phone_no +
        "," +
        shipment.pick_up_location.replaceAll(",", "");
      let receiverDetails =
        shipment.recipient_name +
        "," +
        shipment.recipient_phone_no +
        "," +
        shipment.drop_off_location.replaceAll(",", "");
      let packageDetails =
        shipment.standard_fee.replaceAll(",", "") + "," + shipment.weight;

      let track_number = shipment.shipment_tracking_number;
      let approve = shipment.approved_by;
      let pay_method = shipment.payment_method;
      let pay_Status = shipment.paid_status;
      let ship_status = shipment.status;
      let desc = shipment.item_name;
      let value = shipment.item_value;
      let rider = shipment.rider_name??"";
      let carrier = shipment.processor ?? "";

      const row =
        id +
        "," +
        senderDetails +
        "," +
        receiverDetails +
        "," +
        packageDetails +
        "," +
        track_number +
        "," +
        approve +
        "," +
        pay_method +
        "," +
        pay_Status +
        "," +
        ship_status +
        "," +
        desc +
        "," +
        value +
        "," +
        rider +
        "," +
        carrier;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "shipments1.csv");
    document.body.appendChild(link);

    link.click();
  };

  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>Shipment Reports</h5>
            <span>Home</span>
          </div>
          <div className="container shipments-container">
            <div className="card bg-light border-0 shadow p-4 min-vh-100">
              <div className="d-flex gap-3 mb-3 mb-md-0 flex-column flex-md-row justify-content-between">
                <div className="d-flex flex-column flex-md-row align-items-md-end gap-3">
                  {/* <select onChange={onChangeReportType} style={{width:230, marginRight:10}} name="pickup_detail_id" className="form-select" >
                    <option value="">Choose report</option>
                    <option value="delivered shipments">Delivered Shipments</option>
                    <option value="incomplete shipments">Incomplete Shipments</option>
                    <option value="cancelled shipments">Cancelled Shipments</option>
                    <option value="unpaid shipments">Unpaid Shipments</option>
                  </select> */}

                  <select
                    onChange={onChangeReportTime}
                    // style={{ width: 200, marginRight: 10 }}
                    name="pickup_detail_id"
                    className="form-select input-query"
                  >
                    <option value="">Choose date</option>
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                    <option value="Year">This Year</option>
                    <option value="custom">Custom Date</option>
                  </select>

                  {reportTime === "custom" && (
                    <div className="d-md-flex d-grid col gap-3">
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-select input-query"
                          value={reportStartDate}
                          onChange={(e) => setReportStartDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-select input-query"
                          value={reportEndDate}
                          onChange={(e) => setReportEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    // onClick={fetchShipmentReport}
                    className="confirm-button"
                  >
                    {isLoading ? (
                      <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    ) : (
                      <BiIcons.BiSearch style={{ fontSize: 20 }} />
                    )}
                  </button>
                </div>

                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    EXPORT
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={exportToCSV}>
                      Export To CSV
                    </Dropdown.Item>
                    <Dropdown.Item onClick={exportToXlsx}>
                      Export To Spreadsheet
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="table-contain" >
                <table className="reports-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Sender</th>
                      <th scope="col">Dropoff Location</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Carrier</th>
                      <th scope="col">Tracking No.</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {shipments.map((shipment) => {
                        const dateTime = moment(shipment.date_time);
                        const timestamp = dateTime.format("ddd MMM Do, h:mm A");

                        return (
                          <tr
                            key={shipment.id}
                            onClick={() => handleClick(shipment.id)}
                          >
                            <td>{shipment.sender_name}</td>
                            <td>{shipment.drop_off_location}</td>
                            <td>
                              ₦
                              {parseFloat(shipment.total_amount).toLocaleString(
                                "en"
                              )}
                            </td>
                            <td>{shipment.processor ?? "N/A"}</td>
                            <td>{shipment.shipment_tracking_number ?? "N/A"}</td>
                            <td>{timestamp}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {isRetrievingMore === false ||
              isOptionSelected === "" ||
              isLoading === true ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}
              {isOptionSelected !== "" ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletReports;

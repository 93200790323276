import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import CreateLocalShipmentForm from "./CreateShipmentForms/CreateLocalShipmentForm";
import CreateInterShipmentForm from "./CreateShipmentForms/DHL/CreateInterShipmentForm";
import CreateInternationalTerminalShipmentForm from "./CreateShipmentForms/Terminal/CreateInternationalTerminalShipmentForm";
import {SessionManagement} from "../library/SessionManagement";
import CreateInterstateTerminalShipmentForm from "./CreateShipmentForms/Terminal/CreateInterstateTerminalShipmentForm";
import CreateInterstateDHLShipmentForm from "./CreateShipmentForms/DHL/CreateInterstateDHLShipmentForm";
import SubscriptionInactive from "../components/SubscriptionInactive";

const CreateShipment = () => {
  const [value, setValue] = useState("1");

  const handleValue = (event, newValue) => setValue(newValue);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Create Shipment</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid">
            <div className="card bg-light border-0 shadow">
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleValue} aria-label="lab API tabs example">
                    <Tab label="INTRACITY SHIPMENT" value="1" />
                    <Tab label="INTERSTATE SHIPMENT" value="2" />
                    <Tab label="INTERNATIONAL SHIPMENT" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <CreateLocalShipmentForm />
                </TabPanel>
                <TabPanel value="2">
                  {
                    SessionManagement.getInterstateShipmentProcessor() === "Terminal" ?
                        <CreateInterstateTerminalShipmentForm /> : <CreateInterstateDHLShipmentForm />
                  }
                </TabPanel>
                <TabPanel value="3">
                  {
                    SessionManagement.getInternationalShipmentProcessor() === "Terminal" ?
                        <CreateInternationalTerminalShipmentForm /> : <CreateInterShipmentForm />
                  }
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateShipment;

import "../styles/home.scss";
import "../styles/components.scss";
import "../styles/settings.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import * as React from "react";
import { useState } from "react";
import PersonalDetails from "./Widgets/Settings/PersonalDetails";
import CompanyDetails from "./Widgets/Settings/CompanyDetails";
import SubscriptionPlans from "./Widgets/Settings/SubscriptionPlans";
import NotificationSettings from "./Widgets/Settings/NotificationSettings";
import ShipmentSettings from "./Widgets/Settings/ShipmentSettings";
import PriceSettings from "./Widgets/Settings/PriceSettings";
import AccountSettings from "./Widgets/Settings/AccountSettings";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { useSearchParams } from "react-router-dom";
import { SessionManagement } from "../library/SessionManagement";

const Settings = () => {
    const [searchParam] = useSearchParams();

    const [activeTab, setActiveTab] = useState("PersonalDetails");

    const pageParam = searchParam.get("page");

    React.useEffect(() => {
        if (pageParam === "subscriptions") {
            setActiveTab("SubscriptionPlans");
        }
    }, [pageParam]);

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Settings</h5>
                        <span>Home</span>
                    </div>

                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-xl-12 col-md-12">
                                <div className="card bg-light border-0 shadow">
                                    <div className="card-body">
                                        <div>
                                            <span
                                                style={{ paddingLeft: 0 }}
                                                onClick={(e) => setActiveTab("PersonalDetails")}
                                                className={`profile-tab ${activeTab === "PersonalDetails"
                                                        ? "active-profile"
                                                        : null
                                                    }`}
                                            >
                                                Personal Details
                                            </span>
                                            {SessionManagement.getEmployeeType() ===
                                                "Super Admin" && (
                                                    <span
                                                        onClick={(e) => setActiveTab("CompanyDetails")}
                                                        className={`profile-tab ${activeTab === "CompanyDetails"
                                                                ? "active-profile"
                                                                : null
                                                            }`}
                                                    >
                                                        Company Details
                                                    </span>
                                                )}
                                            <span
                                                onClick={(e) => setActiveTab("NotificationSettings")}
                                                className={`profile-tab ${activeTab === "NotificationSettings"
                                                        ? "active-profile"
                                                        : null
                                                    }`}
                                            >
                                                Notifications
                                            </span>
                                            <span
                                                onClick={(e) => setActiveTab("AccountSettings")}
                                                className={`profile-tab ${activeTab === "AccountSettings"
                                                        ? "active-profile"
                                                        : null
                                                    }`}
                                            >
                                                Account Settings
                                            </span>
                                            <span
                                                onClick={(e) => setActiveTab("PriceSettings")}
                                                className={`profile-tab ${activeTab === "PriceSettings"
                                                        ? "active-profile"
                                                        : null
                                                    }`}
                                            >
                                                Price Settings
                                            </span>
                                            {/*<span onClick={(e)=> setActiveTab("ShipmentSettings")}*/}
                                            {/*          className={`profile-tab ${activeTab === "ShipmentSettings" ? "active-profile" : null}`}>*/}
                                            {/*    Shipment Settings*/}
                                            {/*</span>*/}
                                            <span
                                                onClick={(e) => setActiveTab("SubscriptionPlans")}
                                                className={`profile-tab ${activeTab === "SubscriptionPlans"
                                                        ? "active-profile"
                                                        : null
                                                    }`}
                                            >
                                                Subscription Plans
                                            </span>
                                        </div>
                                        <div
                                            style={{ minHeight: 500 }}
                                            className={`${activeTab === "PersonalDetails"
                                                    ? "show-customer-tab"
                                                    : "hide-customer-tab"
                                                }`}
                                        >
                                            <PersonalDetails />
                                        </div>
                                        <div
                                            style={{ minHeight: 500 }}
                                            className={`${activeTab === "CompanyDetails"
                                                    ? "show-customer-tab"
                                                    : "hide-customer-tab"
                                                }`}
                                        >
                                            <CompanyDetails />
                                        </div>
                                        <div
                                            style={{ minHeight: 500 }}
                                            className={`${activeTab === "NotificationSettings"
                                                    ? "show-customer-tab"
                                                    : "hide-customer-tab"
                                                }`}
                                        >
                                            <NotificationSettings />
                                        </div>
                                        <div
                                            style={{ minHeight: 500 }}
                                            className={`${activeTab === "AccountSettings"
                                                    ? "show-customer-tab"
                                                    : "hide-customer-tab"
                                                }`}
                                        >
                                            <AccountSettings />
                                        </div>
                                        <div
                                            style={{ minHeight: 500 }}
                                            className={`${activeTab === "PriceSettings"
                                                    ? "show-customer-tab"
                                                    : "hide-customer-tab"
                                                }`}
                                        >
                                            <PriceSettings />
                                        </div>

                                        <div
                                            style={{ minHeight: 500 }}
                                            className={`${activeTab === "ShipmentSettings"
                                                    ? "show-customer-tab"
                                                    : "hide-customer-tab"
                                                }`}
                                        >
                                            <ShipmentSettings />
                                        </div>

                                        <div
                                            className={`${activeTab === "SubscriptionPlans"
                                                    ? "show-customer-tab"
                                                    : "hide-customer-tab"
                                                }`}
                                        >
                                            <SubscriptionPlans />
                                        </div>
                                    </div>
                                </div>

                                <div className="row  mt-3">
                                    <div className="col-xl-6 col-md-3">
                                        <div className="card bg-light border-0 shadow"></div>
                                    </div>

                                    <div className="col-xl-6 col-md-3">
                                        <div className="card bg-light border-0 shadow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;

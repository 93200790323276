import * as React from "react";
import styled from "styled-components";
import axios from "axios";

const DHLShipmentContainer = ({
  processor,
  dhlProcessed,
  waybillURL,
  trackingNumber,
  invoiceURL,
  status
}) => {
  return (
    <div className="col-xl-6 col-md-12">
      <span className="header">Processor Shipment Details</span>
      <div className="card bg-light border-0 shadow">
        <div className="card-body shipper-container">
          <div style={{ width: "100%" }}>
            {dhlProcessed === "Yes" ? (
              <Processed
                trackingNumber={trackingNumber}
                waybillURL={waybillURL}
                invoiceURL={invoiceURL}
                status = {status}
              />
            ) : (
              <NotProcessed>
                  <p>Oops... Sorry, you are yet to process this shipment</p>
              </NotProcessed>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const NotProcessed = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Processed = ({ waybillURL, trackingNumber, invoiceURL, status }) => {
    const downloadFile = (url) =>{
        const headers = {
            'Content-Type': 'application/pdf'
        }
        console.log("hello")
        // url = "https://admin.parcelflow.io/engine/v1/actions/get-shipment-invoice.php"
        axios({
            url: url, //your url
            method: 'GET',
            headers: headers, // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });

        // return false;
    }
  return (
    <div>
      <div>
        <span className="title">Tracking Number</span>
        <p>{trackingNumber}</p>
      </div>
      <div>
        <span className="title">Waybill</span>
          {status === "Cancelled" ?
              <p>Shipment has been cancelled and waybill can't be viewed</p> :
              <p>
                  Click{" "}
                  <a style={{textDecoration:"none"}}  target="_blank" href={waybillURL}>
                      here
                  </a>{" "}
                  to view the waybill
              </p>
          }
      </div>
        <div>
            <span className="title">Invoice</span>
            <p>
                Click{" "}
                <a style={{textDecoration:"none"}}  target="_blank" href={invoiceURL}>
                    here
                </a>{" "}
                to view the invoice
            </p>
        </div>
    </div>
  );
};

export default DHLShipmentContainer;

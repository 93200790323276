import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { registerWhatsappPhoneURL } from "../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../library/constants";

const SubmitButton = styled.button`
  width: 250px;
`;


const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const ConfirmPhone = ({ setSubmitted, setCurrentStep, formData }) => {
  const [confirm, setConfirm] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const submit = () => {
    setCurrentStep(3);
    setSubmitted(2);
    setConfirm("");
  };

  const registerNumber = (e) => {
    e.preventDefault();

    if(confirm === "") {
      setErrorVisibility(true);
      setErrorMessage("Select an option");
      return
    }

    setErrorVisibility(false);

    const newData = { params: {
      phone_number: formData?.phone_number, mode: confirm
    }, ...UserProfileData(), level: "2" };

    console.log(newData);

    setIsLoading(true);

    axios
      .post(registerWhatsappPhoneURL, newData)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);

        if (response["data"]["success"] === false) {
          // window.scrollTo(0, 0);
          // submit();
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          // window.scrollTo(0, 0);
          submit();

          setSuccessVisibility(true);
          setSuccessMessage("Level01 updated successfully.");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <div>
      {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess=== false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }
      <p className="Add-phone-form-info" style={{marginBottom:"32px"}} >
        You are about to enter register this number +234 803830 3787 to your
        WhatsApp business account.
      </p>
      <p className="Add-phone-form-info" style={{marginBottom:"64px"}}>
        You will be sent a verification code the this phone number
      </p>
      <p className="Add-phone-form-info" style={{marginBottom:"32px"}}>
      Please choose a preferred mode to receive the code and cllick the button below to continue
      </p>
      <Row className="mb-3">
        <Col className="form-group col-xl-6 col-md-6 col-12">
          <input
            name="confirm"
            className=""
            type="radio"
            onChange={()=>setConfirm("SMS")}
            checked={confirm === "SMS"}
          />
          <label htmlFor="SMS">SMS</label>
        </Col>
        <Col className="form-group col-xl-6 col-md-6 col-12">
          <input
            name="confirm"
            className=""
            type="radio"
            onChange={()=>setConfirm("Voice")}
            checked={confirm === "Voice"}
          />
          <label htmlFor="Voice">Voice</label>
        </Col>
      </Row>
      <div className="d-flex flex-row my-5 justify-content-end">
          <SubmitButton type="button" className="confirm-button" onClick={registerNumber} >
            {isLoading === false ? (
                                <span className="button-text">SEND VERIFICATION CODE</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
          </SubmitButton>
        </div>
    </div>
  );
};

export default ConfirmPhone;

import {useEffect, useState} from "react";
import axios from "axios";
import {getAllAdminsURL} from "../library/URLs";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {HiOutlineDotsHorizontal} from "react-icons/hi";
import * as React from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import * as BsIcon from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import SubscriptionInactive from "../components/SubscriptionInactive";
import SuspendAdminDialog from "../modals/SuspendAdminDialog";
import UnsuspendAdminDialog from "../modals/UnsuspendAdminDialog";
import DeleteAdminDialog from "../modals/DeleteAdminDialog";
import { SessionManagement } from "../library/SessionManagement";

const ProfileImage = styled.img`
    border-radius:50%;
    width:65px;
    height:65px;    
    cursor:pointer;
`;

const Admins = () => {
    const navigator = useNavigate();

    const [admins, setAdmins] = useState([]);
    const [isRetrieving, setIsRetrieving] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const[userActionMenuVisibility, setUserActionMenuVisibility] = useState();
    const [deleteAdminOpen, setDeleteAdminOpen] = useState(null);
    const [isUnsuspendAdminModalVisible, setIsUnsuspendAdminModalVisible] = useState(null);
    const [isSuspendAdminModalVisible, setIsSuspendAdminModalVisible] = useState(null);

    const toggleUserActionMenu1 = (id) => {
        if(userActionMenuVisibility === id) {
            setUserActionMenuVisibility(null);
        } else {
            setUserActionMenuVisibility(id);
        }
    };

    const handleCloseModal = () => {
        setIsUnsuspendAdminModalVisible(null);
        setIsSuspendAdminModalVisible(null);
        setDeleteAdminOpen(null);
    };

    const handleOpenModal = (id, action) => {
        if(action === "delete") {
            setDeleteAdminOpen(id);
        } else if(action === "suspend") {
            setIsSuspendAdminModalVisible(id);
        } else if(action === "unsuspend") {
            setIsUnsuspendAdminModalVisible(id);
        }
    };

    const data = {
        limit: 6,
        offset: 0,
    };

    const newData = { ...data, ...UserProfileData() };

    const fetchAllAdmins = () => {
        axios
        .post(getAllAdminsURL, newData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response);
            setIsRetrieving(false);

            if (response["data"]["success"] === false) return;

            const transactions = response["data"]["data"];

            if (transactions.length === 0)
                setIsEmpty(true);

            setAdmins(transactions);
        });
    }

    useEffect(() => {
        fetchAllAdmins();
    }, []);

    const goToSingleAdmin = (adminID) => {
        navigator("/admin/" + adminID);
    };

    const toggleUserActionMenu = () => {
        console.log("hello");
    }

    return (
        <div className="home">
            <Sidebar />

            <DeleteAdminDialog
                isModalVisible={deleteAdminOpen !== null}
                handleCloseDialog={handleCloseModal}
                adminID={deleteAdminOpen}
                updateEmployees={fetchAllAdmins}
            />

            <UnsuspendAdminDialog
                isModalVisible={isUnsuspendAdminModalVisible !== null}
                handleCloseDialog={handleCloseModal}
                customerID={isUnsuspendAdminModalVisible}
            />

            <SuspendAdminDialog
                isModalVisible={isSuspendAdminModalVisible !== null}
                handleCloseDialog={handleCloseModal}
                customerID={isSuspendAdminModalVisible}
            />
            <div className="main">
                <Navbar />
                {/*{isError === true ? (*/}
                {/*    <ErrorNotification message={errorMessage}>*/}
                {/*        This is a notification*/}
                {/*    </ErrorNotification>*/}
                {/*) : null}*/}

                {/*{isSuccess === true ? (*/}
                {/*    <SuccessNotification message={successMessage}>*/}
                {/*        This is a notification*/}
                {/*    </SuccessNotification>*/}
                {/*) : null}*/}
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Admins</h5>
                        <span>Home</span>
                    </div>

                    {/*<MakeContractUserDialog*/}
                    {/*    isModalVisible={isContractUserModalVisible}*/}
                    {/*    handleCloseDialog={handleCloseModal}*/}
                    {/*    customerID={customerID}*/}
                    {/*    getContractDetails={true}*/}
                    {/*/>*/}

                    {/*<MakeRegularUserDialog*/}
                    {/*    isModalVisible={isRegularUserModalVisible}*/}
                    {/*    handleCloseDialog={handleCloseModal}*/}
                    {/*    customerID={customerID}*/}
                    {/*/>*/}

                    <div className="container-fluid">
                        <div className="row mt-4 ">
                            <div className="d-flex justify-content-end">
                                <button onClick={()=> navigator("/create-admin")} style={{marginRight:8}} className="confirm-button d-flex justify-content-center">
                                    CREATE ADMIN
                                </button>
                            </div>
                            <div className="row mt-4 pr-none">
                                {admins.map((admin) => {
                                    return (
                                        <div key={admin.id} className="col-xl-3 col-md-6 mb-3 mr-3 pr-none">
                                            <div className="card bg-light border-0 shadow">
                                                <div style={{padding:0}} className="card-body shipper-container">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <div style={{width:"100%"}} className="d-flex justify-content-end">
                                                            <div className="user-menu-container">
                                                                <HiOutlineDotsHorizontal onClick={()=>toggleUserActionMenu1(admin.id)} style={{width:20, cursor:"pointer", marginRight:10}} className="icon"/>
                                                                <div className={`user-menu ${userActionMenuVisibility === admin.id ? "user-menu-visible" : null}`}>
                                                                    {
                                                                        SessionManagement.getUserPrivileges().delete_employee === 1 && (
                                                                            <span onClick={()=>handleOpenModal(admin.id, "delete")} >Delete Admin</span>
                                                                        )
                                                                    }
                                                                    {/* <span onClick={()=>handleOpenModal(admin.id, "suspend")} >Suspend Admin</span> */}
                                                                    {/* <span>Deduct From Wallet</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ProfileImage onClick={(e)=>goToSingleAdmin(admin.id)} src={admin.profile_image_url} />
                                                        <div style={{textAlign: 'center', marginTop:20}}>
                                                            <span className="title">{admin.name}</span>
                                                            <p>{admin.employee_type}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-light d-flex justify-content-around">
                                                    <div onClick={(e)=>goToSingleAdmin(admin.id)} >
                                                        Profile
                                                    </div>
                                                    <div>
                                                        Activities
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admins
import * as BoxIcons from "react-icons/bi";
import * as React from "react";
import {SessionManagement} from "../../../library/SessionManagement";
import {getCompanySettingsURL} from "../../../library/URLs";
import SubscribeToPlan from "../../../modals/SubscribeToPlanDialog";
import {useState} from "react";
import SubscriptionSuccessful from "../../../modals/SubscriptionSuccessfulDialog";
import SubscriptionSuccessfulDialog from "../../../modals/SubscriptionSuccessfulDialog";
import { URLConnection } from "../../../library/URLConnection";
import { UserProfileData } from "../../../library/constants";


const SubscriptionPlans = () => {
    const [isSubscriptionPlanDialogVisible, setIsSubscriptionPlanDialogVisible] = useState(false);
    const [isSubscriptionSuccessfulDialogVisible, setIsSubscriptionSuccessfulDialogVisible] = useState(false);
    const [selectedPlanName, setSelectedPlanName] = useState("");
    const [selectedPlanCode, setSelectedPlanCode] = useState("");
    const [selectedAmount, setSelectedAmount] = useState(100);

    React.useEffect(() => {
        URLConnection(
            getCompanySettingsURL,
            UserProfileData(),
            function (response) {
              if (response["data"]["success"] === false) return;
      
              const currentPlan = response["data"]["data"]["current_plan"];

              SessionManagement.setCurrentPlan(currentPlan);
            }
          );
    }, [])

    const handleCloseModal = (refresh) => {
        setIsSubscriptionPlanDialogVisible(false);

        if (refresh === "SubscriptionSuccessful"){
            SessionManagement.setCurrentPlan(selectedPlanName);
            setIsSubscriptionSuccessfulDialogVisible(true);
            window.location.reload();
        }
    };

    const closeSubscriptionSuccessfulDialog = () => {
        setIsSubscriptionSuccessfulDialogVisible(false);
    }

    const showSubscriptionPlanDialog = (planName, planCode, amount) => {
        setSelectedPlanName(planName);
        setSelectedAmount(amount);
        setSelectedPlanCode(planCode);
        setIsSubscriptionPlanDialogVisible(true);
    }

    return(
        <>
            <SubscribeToPlan
                isModalVisible={isSubscriptionPlanDialogVisible}
                handleCloseDialog={handleCloseModal}
                planName={selectedPlanName}
                planCode={selectedPlanCode}
                amount={selectedAmount}
            />

            <SubscriptionSuccessfulDialog
                isModalVisible={isSubscriptionSuccessfulDialogVisible}
                handleCloseDialog={closeSubscriptionSuccessfulDialog}
            />

            <p style={{fontSize:22, marginTop:30, marginBottom:0, fontWeight:"bold"}}>Subscription Plans</p>
            <span>Simple pricing. No hidden fess. Advanced features for your business</span>
            <div className="row mt-4">
                <div className="col-xl-3 te col-md-6 col-sm-12 col">
                    <div className="single-plan">
                        <div><span style={{fontSize:20, color:"#343a40"}}>Basic</span></div>
                        <div style={{height:52}}>
                            <span style={{fontSize:14}}>Perfect for new starters and solo businesses</span>
                        </div>
                        <div style={{marginTop:10}} className="" >
                            {/* <span className="pricing-discount" >₦100k{" "} </span> */}
                            <span className="plan-pricing">₦100k</span><span></span> <span style={{fontSize:12}}>/month</span>
                        </div>
                        {
                            SessionManagement.getCurrentPlan() === "Basic" ?
                            <>
                                <div className="current-plan-button">Current Plan</div>

                            </> :
                            <>
                                <div onClick={(event) => showSubscriptionPlanDialog("Basic", "PLN_w672ws7bqq4uz3j", 10000000)}
                                     className="active-plan-button">Get Basic Plan</div>
                            </>
                        }
                        <div style={{fontSize:14, marginTop:10, color:"#343a40"}}>Basic includes:</div>
                        <div>
                            <div style={{marginTop:3, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 1 Administrator</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 5 transporters</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Up to 200 shipments</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Additional shipment cost 100naira</div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 col">
                    <div className="single-plan">
                        <div><span style={{fontSize:20, color:"#343a40"}}>Standard</span></div>
                        <div style={{height:52}}>
                            <span style={{fontSize:14}}>Best for small businesses</span>
                        </div>
                        <div style={{marginTop:10}}>
                            {/* <span className="pricing-discount" >₦200k{" "} </span> */}
                            <span className="plan-pricing">₦200k</span><span style={{fontSize:12}}>/month</span>
                        </div>
                        {
                            SessionManagement.getCurrentPlan() === "Standard" ?
                                <>
                                    <div className="current-plan-button">Current Plan</div>
                                </> :
                                <>
                                    <div onClick={(event) => showSubscriptionPlanDialog("Standard", "PLN_p9cr0yg7y64nem3", 20000000)}
                                         className="active-plan-button">Get Standard Plan
                                    </div>
                                </>
                        }
                        <div style={{fontSize:14, marginTop:10, color:"#343a40"}}>Standard includes:</div>
                        <div>
                            <div style={{marginTop:3, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 5 Administrators</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 20 transporters</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Additional shipment cost 90naira</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 1000 whatsapp conversations</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> SMS and Emails</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Custom SMS Sender</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Web & Mobile maintenance</div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 col">
                    <div className="single-plan">
                        <div><span style={{fontSize:20, color:"#343a40"}}>Premium</span></div>
                        <div style={{height:52}}>
                            <span style={{fontSize:14}}>Suitable for expanding firms</span>
                        </div>
                        <div style={{marginTop:10}}>
                            {/* <span className="pricing-discount" >₦300k{" "} </span> */}
                            <span className="plan-pricing">₦300k</span><span style={{fontSize:12}}>/month</span>
                        </div>
                        {
                            SessionManagement.getCurrentPlan() === "Premium" ?
                                <>
                                    <div className="current-plan-button">Current Plan</div>
                                </> :
                                <>
                                    <div onClick={(event) => showSubscriptionPlanDialog("Premium", "PLN_9tq1s159ov1f4r4", 30000000)}
                                        className="active-plan-button">Get Premium Plan
                                    </div>
                                </>
                        }
                        <div style={{fontSize:14, marginTop:10, color:"#343a40"}}>Premium includes:</div>
                        <div>
                            <div style={{marginTop:3, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 10 Administrators</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Unlimited transporters</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Additional shipment cost 60naira</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 3000 whatsapp conversations</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> SMS and Emails</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Custom SMS Sender</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Web & Mobile maintenance</div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-12 col">
                    <div className="single-plan">
                        <div><span style={{fontSize:20, color:"#343a40"}}>Enterprise</span></div>
                        <div style={{height:52}}>
                            <span style={{fontSize:14}}>Perfect for independent companies</span>
                        </div>
                        <div style={{marginTop:10}}>
                            <span className="plan-pricing">Custom</span>
                        </div>
                        {
                            SessionManagement.getCurrentPlan() === "Custom" ?
                                <>
                                    <div className="current-plan-button">Current Plan</div>

                                </> :
                                <>
                                    <div className="active-plan-button">
                                        Get Enterprise Plan
                                    </div>
                                </>
                        }
                        {/*<div className="active-plan-button">Contact Us</div>*/}
                        <div style={{fontSize:14, marginTop:10, color:"#343a40"}}>Enterprise includes:</div>
                        <div>
                            <div style={{marginTop:3, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Everything in premium</div>
                            <div style={{marginTop:3, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> 1 Administrator</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Unlimited transporters</div>
                            <div style={{marginTop:6, fontSize:15}}><BoxIcons.BiCheck style={{color:"#008561"}}/> Up to 50 shipments</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionPlans;
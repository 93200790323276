import { Col, Modal, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
    getContractDetailsUserURL,
    makeContractUser2URL,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { BiTrash, BiX } from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const AddBtn = styled.button`
    background: transparent;
    border: none;
    color: #1f7556;
    font-size: .8rem;
    margin-top: .6rem;
    margin-bottom: .4rem
`

const MakeContractUserDialog = ({
    isModalVisible,
    handleCloseDialog,
    customerID,
    getContractDetails,
}) => {
    const form = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRetrieving, setIsRetrieving] = useState(getContractDetails);

    const [localShipmentIsChecked, setLocalShipmentIsChecked] = useState(false);
    const [interstateShipmentIsChecked, setInterstateShipmentIsChecked] =
        useState(false);
    const [internationalShipmentIsChecked, setInternationalShipmentIsChecked] =
        useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [localPricingType, setLocalPricingType] = useState("Standard Price");
    const [localPricingValue, setLocalPricingValue] = useState("");
    const [localPaymentPlan, setLocalPaymentPlan] = useState("No Payment Plan");
    const [localPricingList, setLocalPricingList] = useState([]);

    const [interstatePricingType, setInterstatePricingType] =
        useState("Standard Price");
    const [interstatePricingValue, setInterstatePricingValue] = useState("");
    const [interstatePaymentPlan, setInterstatePaymentPlan] =
        useState("No Payment Plan");
    const [interstatePricingList, setInterstatePricingList] = useState([]);

    const [internationalPricingType, setInternationalPricingType] =
        useState("Standard Price");
    const [internationalPricingValue, setInternationalPricingValue] =
        useState("");
    const [internationalPaymentPlan, setInternationalPaymentPlan] =
        useState("No Payment Plan");
    const [internationalPricingList, setInternationalPricingList] = useState([]);

    const [localPricingValueLabel, setLocalPricingValueLabel] = useState(
        "Standard Price For Local"
    );
    const [localPricingValueReadOnly, setLocalPricingValueReadOnly] =
        useState(true);

    const [interstatePricingValueLabel, setInterstatePricingValueLabel] =
        useState("Standard Price For Interstate");
    const [interstatePricingValueReadOnly, setInterstatePricingValueReadOnly] =
        useState(true);

    const [internationalPricingValueLabel, setInternationalPricingValueLabel] =
        useState("Standard Price For Int'l");
    const [
        internationalPricingValueReadOnly,
        setInternationalPricingValueReadOnly,
    ] = useState(true);

    const toggleLocalShipment = () => {
        setLocalShipmentIsChecked(!localShipmentIsChecked);
        if(localPricingList.length < 1) {
            addLocalPricing();
        }
    };

    const toggleInterstateShipment = () => {
        setInterstateShipmentIsChecked(!interstateShipmentIsChecked);
        if(interstatePricingList.length < 1) {
            addInterstatePricing();
        }
    };

    const toggleInternationalShipment = () => {
        setInternationalShipmentIsChecked(!internationalShipmentIsChecked);
        if(internationalPricingList.length < 1) {
            addInternationalPricing();
        }
    };

    const makeContractUser = (e) => {
        e.preventDefault();

        const updatedLocal = localPricingList?.length > 0 && 
        localPricingList?.map((item) => {return({...item,payment_plan:localPaymentPlan})});

        const updatedInterstate = interstatePricingList?.length > 0 && 
        interstatePricingList?.map((item) => {return({...item,payment_plan:interstatePaymentPlan})});

        const updatedInternational = internationalPricingList?.length > 0 && 
        internationalPricingList?.map((item) => {return({...item,payment_plan:internationalPaymentPlan})});

        const formValues = {
            customer_id: customerID,
            local: localShipmentIsChecked ? updatedLocal : [],
            interstate: interstateShipmentIsChecked ? updatedInterstate : [],
            international: internationalShipmentIsChecked ? updatedInternational : []
        };

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(makeContractUser2URL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(
                        "User has successfully been set to a contract user"
                    );

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog("Refresh");
                    }, 3000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    React.useEffect(() => {
        if (getContractDetails === true) {
            const formValues = {};
            formValues["customer_id"] = customerID;
    
            const newData = { ...formValues, ...UserProfileData() };
    
            axios
                .post(getContractDetailsUserURL, newData)
                .then(function (response) {
                    setIsRetrieving(false);
    
                    if (response["data"]["success"] === true) {
                        const contractDetails = response["data"]["data"];
                        let localCopyList = [...localPricingList];
                        let interstateCopyList = [...interstatePricingList];
                        let internationalCopyList = [...internationalPricingList];
    
                        contractDetails.forEach((details) => {
                            let pricingType = details?.pricing_type;
                            let pricingValue = details?.pricing_value;
                            let carrierValue = details?.carrier;
                            let payPlan = details?.payment_plan;
    
                            let currentPlan = {
                                pricing_type: pricingType,
                                pricing_value: pricingValue,
                                carriers: carrierValue,
                                payment_plan: payPlan
                            }
    
                            if (
                                details.shipment_type === "Local" &&
                                details.status === "active"
                            ) {
                                setLocalShipmentIsChecked(true);
                                localCopyList.push(currentPlan);
                                setLocalPaymentPlan(payPlan)
    
                            }
                            if (
                                details.shipment_type === "Interstate" &&
                                details.status === "active"
                            ) {
                                setInterstateShipmentIsChecked(true);
    
                                interstateCopyList.push(currentPlan)
                                setInterstatePaymentPlan(payPlan)
                            }
                            if (
                                details.shipment_type === "International" &&
                                details.status === "active"
                            ) {
                                setInternationalShipmentIsChecked(true);
    
                                internationalCopyList.push(currentPlan)
                                setInternationalPaymentPlan(payPlan)
                            }
                        });

                        setLocalPricingList(localCopyList);
                        setInterstatePricingList(interstateCopyList);
                        setInternationalPricingList(internationalCopyList);
                    }
                })
                .catch(function (error) {
                    return error;
                });
        }
    }, [getContractDetails])

    const onChangeLocalPricingType = (event) => {
        if (event.target.value === "Standard Price") {
            setLocalPricingValueLabel("Standard Price For Local");
            setLocalPricingValueReadOnly(true);
        } else if (event.target.value === "Fixed Price") {
            setLocalPricingValueLabel("Fixed Price For Local");
            setLocalPricingValueReadOnly(false);
        } else if (event.target.value === "Discounted Price") {
            setLocalPricingValueLabel("Percentage Off For Local");
            setLocalPricingValueReadOnly(false);
        }
    };

    const onChangeInterstatePricingType = (event) => {
        if (event.target.value === "Standard Price") {
            setInterstatePricingValueLabel("Standard Price For Interstate");
            setInterstatePricingValueReadOnly(true);
        } else if (event.target.value === "Fixed Price") {
            setInterstatePricingValueLabel("Fixed Price For Interstate");
            setInterstatePricingValueReadOnly(false);
        } else if (event.target.value === "Discounted Price") {
            setInterstatePricingValueLabel("Percentage Off For Interstate");
            setInterstatePricingValueReadOnly(false);
        }
    };

    const onChangeInternationalPricingType = (event) => {
        if (event.target.value === "Standard Price") {
            setInternationalPricingValueLabel("Standard Price For Int'l");
            setInternationalPricingValueReadOnly(true);
        } else if (event.target.value === "Fixed Price") {
            setInternationalPricingValueLabel("Fixed Price For Int'l");
            setInternationalPricingValueReadOnly(false);
        } else if (event.target.value === "Discounted Price") {
            setInternationalPricingValueLabel("Percentage Off For Int'l");
            setInternationalPricingValueReadOnly(false);
        }
    };

    const handleLocalPricing = (e, id) => {
        const { name, value } = e.target;

        let localPricingCopy = [...localPricingList];
        let updatedPricingInfo = {
            ...localPricingCopy[id],
            [name]: value,
        };

        localPricingCopy[id] = updatedPricingInfo;

        setLocalPricingList(localPricingCopy);
    };

    const addLocalPricing = () => {
        let localPricingCopy = [...localPricingList];

        localPricingCopy.push({});
        setLocalPricingList(localPricingCopy);
    };

    const removeLocalPricing = (id) => {
        let localPricingCopy = [...localPricingList];

        localPricingCopy.splice(id, 1);

        setLocalPricingList(localPricingCopy);
    };

    // Interstate Functions
    const handleInterstatePricing = (e, id) => {
        const { name, value } = e.target;

        let interstatePricingCopy = [...interstatePricingList];
        let updatedPricingInfo = {
            ...interstatePricingCopy[id],
            [name]: value,
        };

        interstatePricingCopy[id] = updatedPricingInfo;

        setInterstatePricingList(interstatePricingCopy);
    };

    const addInterstatePricing = () => {
        let interstatePricingCopy = [...interstatePricingList];

        interstatePricingCopy.push({});
        setInterstatePricingList(interstatePricingCopy);
    };

    const removeInterstatePricing = (id) => {
        let interstatePricingCopy = [...interstatePricingList];

        interstatePricingCopy.splice(id, 1);

        setInterstatePricingList(interstatePricingCopy);
    };

    // International functions
    const handleInternationalPricing = (e, id) => {
        const { name, value } = e.target;

        let internationalPricingCopy = [...internationalPricingList];
        let updatedPricingInfo = {
            ...internationalPricingCopy[id],
            [name]: value,
        };

        internationalPricingCopy[id] = updatedPricingInfo;

        setInternationalPricingList(internationalPricingCopy);
    };

    const addInternationalPricing = () => {
        let internationalPricingCopy = [...internationalPricingList];

        internationalPricingCopy.push({});
        setInternationalPricingList(internationalPricingCopy);
    };

    const removeInternationalPricing = (id) => {
        let internationalPricingCopy = [...internationalPricingList];

        internationalPricingCopy.splice(id, 1);

        setInternationalPricingList(internationalPricingCopy);
    };

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog} dialogClassName="dialog-mid-larger" >
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">
                    MAKE CONTRACT USER
                </h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                {isRetrieving === true ? (
                    <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                        <span>Retrieving contract details...</span>
                    </LoadingContainer>
                ) : (
                    <form ref={form} onSubmit={makeContractUser}>
                        <div className="local-shipment-form form-check">
                            <input
                                checked={localShipmentIsChecked}
                                onChange={toggleLocalShipment}
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="localShipment"
                            />
                            <label className="form-check-label" htmlFor="localShipment">
                                Local Shipment
                            </label>
                        </div>

                        {localShipmentIsChecked === false ? null : (
                            <div>
                                {localPricingList.map((pricingInfo, id) => (
                                    <Row className="mt-3" key={id}>
                                        <div className="d-flex justify-content-end mb-2" >
                                                <BiTrash 
                                                    onClick={localPricingList?.length > 1 ? () => removeLocalPricing(id) : () => {}} 
                                                    className={`${localPricingList?.length > 1 ? "text-danger" : "text-muted"}`} 
                                                />
                                            </div>
                                        <Col xs={12} md={6} lg={4} >
                                            <label htmlFor="Sender Postal">
                                                Pricing Plan For Local
                                            </label>
                                            <select
                                                onChange={(e) => handleLocalPricing(e, id)}
                                                name="pricing_type"
                                                className="form-select"
                                                value={pricingInfo?.pricing_type}
                                            >
                                                <option value="Standard Price">Standard Price</option>
                                                <option value="Fixed Price">Fixed Price</option>
                                                <option value="Discounted Price">
                                                    Discounted Price
                                                </option>
                                            </select>
                                        </Col>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Fixed Price">
                                                {pricingInfo?.pricing_type === "Standard Price"
                                                    ? "Standard Price For Local"
                                                    : pricingInfo?.pricing_value === "Fixed Price"
                                                        ? "Fixed Price For Local"
                                                        : "Percentage Off For Local"}
                                            </label>
                                            <input
                                                name="pricing_value"
                                                onChange={(e) => handleLocalPricing(e, id)}
                                                className="form-control"
                                                type="text"
                                                value={pricingInfo?.pricing_value}
                                                readOnly={
                                                    pricingInfo?.pricing_type === "Standard Price"
                                                }
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Fixed Price">Carrier</label>
                                            <select
                                                onChange={(e) => handleLocalPricing(e, id)}
                                                name="carriers"
                                                className="form-select"
                                                value={pricingInfo?.carriers}
                                            >
                                                <option value="" hidden>Choose Carrier</option>
                                                <option value="DHL">DHL</option>
                                                <option value="Topship">Topship</option>
                                                <option value="Fedex">Fedex</option>
                                                <option value="Aramex">Aramex</option>
                                                <option value="Terminal">Terminal</option>
                                                <option value="UPS">UPS</option>
                                            </select>
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-end" >
                                            <AddBtn type="button" onClick={addLocalPricing} >
                                                Add another discount
                                            </AddBtn>
                                        </Col>
                                    </Row>
                                ))}
                                <div className="mt-2">
                                    <label htmlFor="Payment Plan">Payment Plan</label>
                                    <select
                                        name="local_payment_plan"
                                        className="form-select"
                                        defaultValue={localPaymentPlan}
                                    >
                                        <option value="No Payment Plan">No Payment Plan</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </div>
                            </div>
                        )}

                        <div className="local-shipment-form form-check mt-4">
                            <input
                                checked={interstateShipmentIsChecked}
                                onChange={toggleInterstateShipment}
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="interstateShipment"
                            />
                            <label className="form-check-label" htmlFor="interstateShipment">
                                Interstate Shipment
                            </label>
                        </div>

                        {interstateShipmentIsChecked === false ? null : (
                            <div>
                                {interstatePricingList.map((pricingInfo, id) => (
                                    <Row className="mt-3" key={id}>
                                        <div className="d-flex justify-content-end mb-2" >
                                            <BiTrash 
                                                onClick={interstatePricingList?.length > 1 ? () => removeInterstatePricing(id) : () => {}} 
                                                className={`${interstatePricingList?.length > 1 ? "text-danger" : "text-muted"}`} 
                                                style={{cursor: "pointer"}}
                                            />
                                        </div>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Sender Postal">
                                                Pricing Plan For Local
                                            </label>
                                            <select
                                                onChange={(e) => handleInterstatePricing(e, id)}
                                                name="pricing_type"
                                                className="form-select"
                                                value={pricingInfo?.pricing_type}
                                            >
                                                <option value="Standard Price">Standard Price</option>
                                                <option value="Fixed Price">Fixed Price</option>
                                                <option value="Discounted Price">
                                                    Discounted Price
                                                </option>
                                            </select>
                                        </Col>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Fixed Price">
                                                {pricingInfo?.pricing_type === "Standard Price"
                                                    ? "Standard Price For Local"
                                                    : pricingInfo?.pricing_value === "Fixed Price"
                                                        ? "Fixed Price For Local"
                                                        : "Percentage Off For Local"}
                                            </label>
                                            <input
                                                name="pricing_value"
                                                onChange={(e) => handleInterstatePricing(e, id)}
                                                className="form-control"
                                                type="text"
                                                value={pricingInfo?.pricing_value}
                                                readOnly={
                                                    pricingInfo?.pricing_type === "Standard Price"
                                                }
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Fixed Price">Carrier</label>
                                            <select
                                                onChange={(e) => handleInterstatePricing(e, id)}
                                                name="carriers"
                                                className="form-select"
                                                value={pricingInfo?.carriers}
                                            >
                                                <option value="" hidden>Choose Carrier</option>
                                                <option value="DHL">DHL</option>
                                                <option value="Topship">Topship</option>
                                                <option value="Fedex">Fedex</option>
                                                <option value="Aramex">Aramex</option>
                                                <option value="Terminal">Terminal</option>
                                                <option value="UPS">UPS</option>
                                            </select>
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-end" >
                                            <AddBtn type="button" onClick={addInterstatePricing} >
                                                Add another discount
                                            </AddBtn>
                                        </Col>
                                    </Row>
                                ))}
                                <div className="mt-2">
                                    <label htmlFor="Payment Plan">Payment Plan</label>
                                    <select
                                        name="interstate_payment_plan"
                                        className="form-select"
                                        defaultValue={interstatePaymentPlan}
                                    >
                                        <option value="No Payment Plan">No Payment Plan</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </div>
                            </div>
                        )}

                        <div className="local-shipment-form form-check mt-4">
                            <input
                                checked={internationalShipmentIsChecked}
                                onChange={toggleInternationalShipment}
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="internationalShipment"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="internationalShipment"
                            >
                                International Shipment
                            </label>
                        </div>

                        {internationalShipmentIsChecked === false ? null : (
                            <div>
                                {internationalPricingList.map((pricingInfo, id) => (
                                    <Row className="mt-3" key={id}>
                                        <div className="d-flex justify-content-end mb-2" >
                                            <BiTrash 
                                                onClick={internationalPricingList?.length > 1 ? () => removeInternationalPricing(id) : () => {}} 
                                                className={`${internationalPricingList?.length > 1 ? "text-danger" : "text-muted"}`} 
                                                style={{cursor: "pointer"}}
                                            />
                                        </div>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Sender Postal">
                                                Pricing Plan For Local
                                            </label>
                                            <select
                                                onChange={(e) => handleInternationalPricing(e, id)}
                                                name="pricing_type"
                                                className="form-select"
                                                value={pricingInfo?.pricing_type}
                                            >
                                                <option value="Standard Price">Standard Price</option>
                                                <option value="Fixed Price">Fixed Price</option>
                                                <option value="Discounted Price">
                                                    Discounted Price
                                                </option>
                                            </select>
                                        </Col>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Fixed Price">
                                                {pricingInfo?.pricing_type === "Standard Price"
                                                    ? "Standard Price For Local"
                                                    : pricingInfo?.pricing_value === "Fixed Price"
                                                        ? "Fixed Price For Local"
                                                        : "Percentage Off For Local"}
                                            </label>
                                            <input
                                                name="pricing_value"
                                                onChange={(e) => handleInternationalPricing(e, id)}
                                                className="form-control"
                                                type="text"
                                                value={pricingInfo?.pricing_value}
                                                readOnly={
                                                    pricingInfo?.pricing_type === "Standard Price"
                                                }
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={4}>
                                            <label htmlFor="Fixed Price">Carrier</label>
                                            <select
                                                onChange={(e) => handleInternationalPricing(e, id)}
                                                name="carriers"
                                                className="form-select"
                                                value={pricingInfo?.carriers}
                                            >
                                                <option value="" hidden>Choose Carrier</option>
                                                <option value="DHL">DHL</option>
                                                <option value="Topship">Topship</option>
                                                <option value="Fedex">Fedex</option>
                                                <option value="Aramex">Aramex</option>
                                                <option value="Terminal">Terminal</option>
                                                <option value="UPS">UPS</option>
                                            </select>
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-end" >
                                            <AddBtn type="button" onClick={addInternationalPricing} >
                                                Add another discount
                                            </AddBtn>
                                        </Col>
                                    </Row>
                                ))}
                                <div className="mt-2">
                                    <label htmlFor="Payment Plan">Payment Plan</label>
                                    <select
                                        name="internationale_payment_plan"
                                        className="form-select"
                                        defaultValue={internationalPaymentPlan}
                                    >
                                        <option value="No Payment Plan">No Payment Plan</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    </form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton
                    onClick={makeContractUser}
                    type="submit"
                    className="confirm-button"
                >
                    {isSubmitting === false ? (
                        <span className="button-text">MAKE CONTRACT USER</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default MakeContractUserDialog;
